import React from "react";
import { Link } from 'react-scroll';
import { Trans, useTranslation } from 'react-i18next';
import { animateScroll } from 'react-scroll';
import { GoLinkExternal } from 'react-icons/go';
import { FiMenu, FiX } from 'react-icons/fi';
import Fade from 'react-reveal/Fade';
import mikura_logo from '../assets/mikura_logo.jpg';
import './menu.css';
import { MOBILE_SEPARATOR } from '../config';

export function Menu(props) {
    const { items, dimensions, onClick, inHamburger } = props;
    const { t, i18n } = useTranslation();
    return (
        <ul className="menu">
          {!inHamburger && <li>
             <h1 style={{ margin: `${inHamburger ? '0': '1em'} 0 0 0`, cursor: "pointer" }} onClick={o => animateScroll.scrollToTop({ duration: 300 })}>
               <a>
                 {t('title')}
               </a>
             </h1>
           </li>
}          <li>
            <div className="lang-btn"><a onClick={_ => i18n.changeLanguage('en')}>English</a></div>
            <div className="lang-btn"><a onClick={_ => i18n.changeLanguage('zh-tw')}>中文</a></div>
          </li>
          {items.map(item => (
              <li>
                <h3 style={{ margin: "1em 0" }}><Link to={item.target} spy={true} smooth={true} duration={300} offset={-0.5 * (dimensions?.height ?? 0)} activeClass="activated" onClick={onClick}><Trans key={item.name}>{item.name}</Trans></Link></h3>
              </li>
          ))}
          <li>
            <h3 className='release-party' style={{ margin: "1em 0" }}>
              <a href="https://www.twitch.tv/mikura_2020" target="_blank" className="activated">
                {t('release_party')} <GoLinkExternal /><br/>
                <span className="xx-smaller">Mar. 20th (Sat) 18:00-22:00<br/>on Twitch</span>
              </a>
            </h3>
          </li>
        </ul>
    );
}

export function Hamburger(props){
    const { children } = props;
    const [ collapse, setCollapse ] = React.useState(false);
    const { t, i18n } = useTranslation();
    return (<React.Fragment>
              <div className={`hamburger ${ collapse && 'collapse' }`}>
                <div className='topbar' onClick={_ => setCollapse(!collapse)}>
                  <h2 style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', alignItems: 'center'}}>
            {collapse ? <FiX/> : <FiMenu/>} {t('title')} <img src={mikura_logo} style={{width: '1em', marginBottom: 0}}/>
                  </h2>
                </div>
                {collapse &&
                 <Fade duration={200}>
                   <div className="menu-outer">
                     {React.Children.map(children,
                                         child => React.cloneElement(child, {
                                             onClick: _ => setCollapse(false),
                                             inHamburger: true})
                                        )}
                   </div>
                 </Fade>}
              </div>
            </React.Fragment>);
}

export default Menu;
