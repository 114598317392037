import React from 'react';
import './button.css';

function Button(props) {
    const { children, linkto, disabled } = props;
    if (disabled) {
        return (
            <p className="button" >
                {children}
            </p>
        );
    }
    return (
        <a href={linkto} target='_blank' className="button" >
            {children}
        </a>
    );
}


export default Button;
