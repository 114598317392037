import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Fade from 'react-reveal/Fade';
import Flash from 'react-reveal/Flash';
import { animateScroll } from 'react-scroll';
import { FiChevronDown, FiChevronRight, FiChevronUp, FiPlus, FiMinus, FiPlusCircle, FiMinusCircle, FiHeadphones } from 'react-icons/fi';
import './player.css';

export function Player(props) {
    const { tracks, dimensions } = props;
    const [nowPlaying, setNowPlaying] = useState(null);
    return (<React.Fragment>
        {tracks.map((track, idx) => (<Track serial={idx} isView={nowPlaying === idx} onView={setNowPlaying} dimensions={dimensions} {...track} />))}
        <div className="soundcloud-player middle">
        <Fade bottom duration={500}>
            <ReactPlayer
                url="https://soundcloud.com/2020mikura/mikura-compilation-preview-track"
                width="100%"
                height="80px"
            />
        </Fade>
        </div>
    </React.Fragment>);
}
export function Track(props) {
    const { serial, name, info, isView, dimensions, subtitle, bpm } = props;
    const scrollSelf = () => {
        animateScroll.scrollTo(
            (window.pageYOffset || document.documentElement.scrollTop)
            + document.getElementById(`track-${serial}`).getBoundingClientRect().top
            - (document.getElementById(`track-${serial}`).getBoundingClientRect().height / 2)
            + ((dimensions?.height ?? 0) / 2),
            { duration: 500 }
        );
    };
    return (<React.Fragment>
        <div className={`track open`} id={`track-${serial}`}>
            <div
                className="backdrop"
                // style={{ backgroundImage: `url("${background}")` }}
                style={{ backgroundColor: "var(--main-color-light)" }}
            />
            <div
                className="title"
            >
                <h2>{serial + 1}</h2>
                <div>
                    <h3>{name}</h3>
                    {subtitle && <p className="subtitle">{subtitle}</p>}
                </div>
                <div className="info">{info}</div>
            </div>
        </div>
    </React.Fragment>);
}
