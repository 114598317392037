import React from 'react';

export function Extra(props) {
    const { children } = props;
    return (<React.Fragment>
        <div className="extra">
            {children}
        </div>
    </React.Fragment>);
}
export default Extra;