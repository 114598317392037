import React from 'react';
import * as THREE from 'three';

function sliceImage(image){
    if(image.current){
        image.current.style.maskImage = `linear-gradient:(rgba(0,0,0,1.0), transparent)`;
        requestAnimationFrame(x=>sliceImage(image));
    }
}

export function FloatingBackground(props) {
    const { background, style, innerStyle, className ,...others } = props;
    const image = React.createRef();

    const _ = React.useEffect(()=>sliceImage(image), [image]);
    
    return (<React.Fragment>
              <div className={`${className??''} floating-background`} style={style}>
                <img ref={image} src={background} style={innerStyle}/>
              </div>
    </React.Fragment>);
}
export default FloatingBackground;
