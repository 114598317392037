import React, { Component } from 'react';
import 'normalize.css';
import './App.css';
import './i18n';
import { Trans, useTranslation } from 'react-i18next';
import { TypographyStyle, GoogleFont } from 'react-typography';
import typography from './utils/typography';
import { Element } from 'react-scroll';
import { Menu, Hamburger } from './components/menu';
import Fade from 'react-reveal/Fade';
import Flash from 'react-reveal/Flash';
import Splash from './components/splash';
import { Player } from './components/player';
import SYRlTISvjww from './assets/SYRlTISvjww.jpg';
import M9bq_alksw from './assets/M9bq_alksw.jpg';
import VNkDJk5_9eU from './assets/VNkDJk5_9eU.jpg';
import Extra from './components/extra';
import ReactPlayer from 'react-player';
import JKlJWH961I from './assets/JKlJWH961I.jpg';
import mikura_logo from './assets/mikura_logo.jpg';
import cg_large from './assets/cg_large.jpg';
import cover_bk from './assets/cg_large.jpg';
import { GoLinkExternal } from 'react-icons/go';
import cover_bk_blur from './assets/cg_large_blur.jpg';
import cg_square from './assets/cg_square.jpg';
import ImagePlaceholder from "./assets/placeholder-images-image_large.jpg";
import { FiShoppingCart } from 'react-icons/fi';
import Button from './components/button';
import FloatingBackground from './components/floating_background';
import { FaFacebookF, FaBandcamp, FaTwitter, FaSoundcloud, FaMixcloud, FaYoutube } from 'react-icons/fa';
import { CgWebsite } from 'react-icons/cg';
import { PlurkSvg } from './components/icon.js';
import ExpendingContent from './components/expendingcontent';

function App(props) {
  const { t, i18n } = useTranslation();
  const [background, setBackground] = React.useState(cg_large);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }
    window.addEventListener('resize', handleResize);
    return _ => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    <React.Fragment>
      <head>
        <TypographyStyle typography={typography} />
        <GoogleFont typography={typography} />
      </head>
      <div className="hamburger-outer">
        <Hamburger>
          {/* hamburger menu */}
          <Menu
            items={[
              { name: "intro", target: "intro" },
              { name: "track", target: "track" },
              { name: "thank", target: "thank" },
            ]}
            dimensions={dimensions}
          />
        </Hamburger>
      </div>
      <div className="page-outer">
        <FloatingBackground className="section-outer" background={cover_bk_blur} style={{ gridRow: "splash" }} innerStyle={{ animation: "contrast-blending 5s ease-in-out alternate infinite" }} />
        <div className="section-outer" style={{ gridRow: "splash" }}>
          {/* front page section */}
          <Fade duration={200}>
            <div style={{ position: "sticky", top: 0 }}>
              <Splash />
            </div>
          </Fade>
        </div>
        <FloatingBackground className="section-outer" background={cover_bk} style={{ gridRow: "intro" }} innerStyle={{ filter: "contrast(0.1)" }} />
        <Element name="intro" className="section-outer" style={{ gridRow: "intro" }}>
          {/* intro */}
          <FadeSection>
            <div className="section-title">
              <h2>{t('intro')}</h2>
            </div>
            <ExpendingContent>
              <Trans t={t} i18nKey='introduction' />
              <hr style={{ backgroundColor: "var(--main-color-light)" }} />
              <div className="timeline">
                <ul>
                  <li>
                    <p>2020/04/25 Mi KURA Presents - Яev</p>
                    <p>DJ : 3R2 / DJ Dojou / DJ Jeff Hazama / DiagonalT / Elicca / MU9</p>
                    <p>VJ : 小宗宗 / Yan-k / Hakurei Neko / Gary? / Elicca / Salmon Square</p>
                  </li>
                  <li>
                    <p>2020/05/30 Mi KURA Presents - 再・製Re:maked</p>
                    <p>DJ : DiagonalT / DJ Dojou / 九彌 aka MU9 / DJ Jeff Hazama / Sean Wang / Ice</p>
                    <p>VJ : Gary? / 小宗宗 / DiagonalT</p>
                  </li>
                  <li>
                    <p>2020/06/13 3R2music x Mi KURA presents - 《Summertime》Online Release Party</p>
                    <p>DJ : 3R2 / DJ Jeff Hazama / DiagonalT</p>
                    <p>VJ : Yan-k</p>
                  </li>
                  <li>
                    <p>2020/08/01 Mi KURA Presents - Monochrome</p>
                    <p>DJ : 3R2 / Gary? / DJ Jeff Hazama / 九彌 aka MU9 / Triodust / KillerBlood</p>
                    <p>VJ : Gary? / DiagonalT</p>
                  </li>
                  <li>
                    <p>2020/08/29 暑假未來 Re:live</p>
                    <p>Live : Tragic Boy（翔夜＆貓弟）/ Ruka Banana / Karei & ooo / Sphere Sapphire / 飄 PiaO / B2 / Camellia 茶花苗 / RD＊需要關愛 / 赤式 / 花びラブ</p>
                    <p>DJ : DJ LR / DJ Jeff Hazama / DiagonalT / 3R2 / にーご / Yuyoyuppe - ゆよゆっぺ - DJ’TEKINA//SOMETHING - DtE</p>
                    <p>VJ : Gary? / DJ Dojou</p>
                  </li>
                  <li>
                    <p>2020/09/20 Mi KURA Presents - Respect Your NRG！</p>
                    <p>DJ : Gary? / 無用男Rain / DiagonalT / DJ Dojou / Yao / 小倉唯老公 / Ｇ / ViC / XinG</p>
                    <p>VJ : Gary? / Yao</p>
                  </li>
                  <li>
                    <p>2020/10/31 Mi KURA Presents - Unlimited Freedom Festival ‘20 ( chill DJ Stage )</p>
                    <p>DJ : DiagonalT / DJ Jeff Hazama / Sean Wang</p>
                  </li>
                  <li>
                    <p>2020/11/28 Mi KURA Presents - Disciple</p>
                    <p>DJ : FeCo / 澄乃すみの / RK877 / DJ SOXI / 燐にゃん / NEKOYASHIKI</p>
                    <p>VJ : Gary? / DiagonalT</p>
                  </li>
                </ul>
              </div>
            </ExpendingContent>
          </FadeSection>
        </Element>
        {/* <div style={{ padding: '2em 0', backgroundColor: 'rgb(32,32,32)' }}> */}
        <FloatingBackground className="section-outer" background={cover_bk} style={{ gridRow: "track" }} innerStyle={{ filter: "blur(2px) brightness(0.4)" }} />
        <Element name="track" className="section-outer" style={{ gridRow: "track" }}>
          {/* media player */}
          <FadeSection>
            <div className="section-title">
              <h2>{t('track')}</h2>
            </div>
            <Player
              tracks={[
                {
                  name: 'Activating',
                  mainAuthor: <React.Fragment>
                    <a href='https://www.facebook.com/JeffHazama' target='_blank'>Jeff Hazama</a>
                  </React.Fragment>,
                  subtitle: "由此開始",
                  info: (<React.Fragment>
                    <p>
                      Jeff Hazama
                      <p className="author-type">{t('composition')}/{t('arrangement')}</p>
                      <div className="button-list">
                        <Button linkto='https://www.facebook.com/JeffHazama'><FaFacebookF />Facebook</Button>
                        <Button linkto="https://www.plurk.com/jack5551555"><PlurkSvg />Plurk</Button>
                        <Button linkto="https://twitter.com/JeffHazama"><FaTwitter />Twitter</Button>
                      </div>
                    </p>
                    <p>
                      Sean Wang
                      <p className="author-type">{t('arrangement')}</p>
                      <div className="button-list">
                        <Button linkto='https://www.youtube.com/user/GODGODGOD10'><FaYoutube />YouTube</Button>
                        <Button linkto="https://soundcloud.com/tmpss91136/tracks"><FaSoundcloud />SoundCloud</Button>
                        <Button linkto="https://www.facebook.com/profile.php?id=100000468720983"><FaFacebookF />Facebook</Button>
                        <Button linkto="https://www.mixcloud.com/sean-wang4/"><FaMixcloud />MixCloud</Button>
                      </div>
                    </p>
                  </React.Fragment>),
                },
                {
                  name: 'Melting Point (Carnival Mix)',
                  mainAuthor: <React.Fragment>
                    <a href='https://3r2music.com/' target='_blank'>3R2</a>
                  </React.Fragment>,
                  info: (<React.Fragment>
                    <p>
                      3R2
                      <p className="author-type">{t('composition')}/{t('arrangement')}</p>
                      <div className="button-list">
                        <Button linkto='https://3r2music.com'><CgWebsite /> 3r2music</Button>
                      </div>
                    </p>
                  </React.Fragment>),
                },
                {
                  name: 'Arklight (Extended Ver.)',
                  mainAuthor: <React.Fragment>
                    <a href="https://soundcloud.com/feliaxv" target="_blank">V!C (ΛΣ)</a>
                  </React.Fragment>,
                  info: (<React.Fragment>
                    <p>
                      V!C (ΛΣ)
                      <p className="author-type">{t('composition')}/{t('arrangement')}</p>
                      <div className="button-list">
                        <Button linkto='https://soundcloud.com/feliaxv'><FaSoundcloud />SoundCloud</Button>
                        <Button linkto="https://www.facebook.com/gaming/VicWithElectroAndYou"><FaFacebookF />Facebook</Button>
                        <Button linkto="https://twitter.com/tatamitako_ViC"><FaTwitter />Twitter</Button>
                      </div></p>
                  </React.Fragment>),
                },
                {
                  name: 'Rapsodie fer za Cactuses (*\'v\'*)',
                  info: (<React.Fragment>
                    <p>
                      Spiegel
                      <p className="author-type">{t('composition')}/{t('arrangement')}</p>
                    </p>
                    <p>
                      Ice
                      <p className="author-type">{t('composition')}</p>
                      <div className="button-list">
                        <Button linkto="https://soundcloud.com/iceloki/"><FaSoundcloud />SoundCloud</Button>
                      </div>
                    </p>
                  </React.Fragment>),
                },
                {
                  name: 'Afternoon Fantasy',
                  info: (<React.Fragment>
                    <p>
                      Sean Wang
                      <p className="author-type">{t('composition')}/{t('arrangement')}</p>
                      <div className="button-list">
                        <Button linkto='https://www.youtube.com/user/GODGODGOD10'><FaYoutube />YouTube</Button>
                        <Button linkto="https://soundcloud.com/tmpss91136/tracks"><FaSoundcloud />SoundCloud</Button>
                        <Button linkto="https://www.facebook.com/profile.php?id=100000468720983"><FaFacebookF />Facebook</Button>
                        <Button linkto="https://www.mixcloud.com/sean-wang4/"><FaMixcloud />MixCloud</Button>
                      </div>
                    </p>
                  </React.Fragment>),
                },
                {
                  name: 'Xenophobia',
                  info: (<React.Fragment>
                    <p>
                      XinG
                      <p className="author-type">{t('composition')}/{t('arrangement')}</p>
                      <div className="button-list">
                        <Button linkto='https://soundcloud.com/xing_lo'><FaSoundcloud />SoundCloud</Button>
                        <Button linkto="https://www.facebook.com/xingl0"><FaFacebookF />Facebook</Button>
                        <Button linkto="https://twitter.com/tom61403"><FaTwitter />Twitter</Button>
                        <Button linkto="https://xinglo.bandcamp.com"><FaBandcamp />BandCamp</Button>
                      </div></p>
                  </React.Fragment>),
                },
                {
                  name: '廢墟星球搜奇',
                  subtitle: '敘述著一隊人馬到外星探勘的過程，而這顆滿是廢墟的星球好像有點熟悉...？',
                  info: (<React.Fragment>
                    <p>
                      KillerBlood
                      <p className="author-type">{t('composition')}/{t('arrangement')}</p>
                      <div className="button-list">
                        <Button linkto='https://www.facebook.com/KillerBloodTW'><FaFacebookF />Facebook</Button>
                        <Button linkto="https://killerblood.bandcamp.com"><FaBandcamp />BandCamp</Button>
                        <Button linkto="https://twitter.com/KillerBloodTW"><FaTwitter />Twitter</Button>
                        <Button linkto="https://www.plurk.com/KILLERBLOOD"><PlurkSvg />Plurk</Button>
                      </div>
                    </p>
                  </React.Fragment>),
                },
                {
                  name: '天体地図 (DiagonalT Flip mix)',
                  subtitle: "想跟著喊 チキパmix 的請跟我來。",
                  info: (<React.Fragment>
                    <p>
                      Ruka Banana
                      <p className="author-type">{t('vocal')}</p>
                      <div className="button-list">
                        <Button linkto='https://rukabanana.weebly.com/'><CgWebsite />Promotion Site</Button>
                        <Button linkto='https://www.facebook.com/RukaBanana/'><FaFacebookF />Facebook</Button>
                      </div>
                    </p>
                    <p>
                      DiagonalT
                      <p className="author-type">{t('arrangement')}</p>
                      <div className="button-list">
                        <Button linkto='https://www.facebook.com/djhongtaka'><FaFacebookF /> Facebook</Button>
                        <Button linkto="https://soundcloud.com/hongtaka"><FaSoundcloud /> SoundCloud</Button>
                        <Button linkto="https://www.plurk.com/hongtaka"><PlurkSvg /> Plurk</Button>
                      </div>
                    </p>
                    <p>
                      里咲りさ
                      <p className="author-type">{t('lyric')}/{t('composition')}</p>
                    </p>
                  </React.Fragment>),
                },
              ]}
            />
          </FadeSection>
        </Element>
        <div
          className="soundcloud-player-outer section-outer left"
          style={{ gridRow: "track", display: "flex", flexDirection: "column", alignContent: "flex-end" }}>
          <div style={{ height: "calc( 100% - 260px)" }} />
          <div style={{ display: "flex", flexDirection: "row", width: "100%", position: "sticky", bottom: 0, justifyContent: "flex-end", marginBottom: "calc(2em + 10vw)", marginTop: "calc(5vw + 3.3333em)" }}>
            <Fade bottom duration={500}>
              <ReactPlayer
                className="soundcloud-player"
                url="https://soundcloud.com/2020mikura/mikura-compilation-preview-track"
                width="100%"
              />
            </Fade>
          </div>
        </div>
        <FloatingBackground className="section-outer" background='https://i.imgur.com/Wawa4qA.jpg' style={{ gridRow: "thank" }} innerStyle={{ filter: "contrast(0.2) blur(5px)" }} />
        <Element name="thank" className="section-outer" style={{ gridRow: "thank" }}>
          {/* connects */}
          <FadeSection>
            <div className="section-title">
              <h2>{t('thank')}</h2>
            </div>
            <Extra>
              <div>
                <img src="https://i.imgur.com/BYSzvXL.png" style={{ width: "300px", maxWidth: "100%", marginBottom: "0.5em" }} />
                <div className="button-list" style={{ marginBottom: "1.66667em" }}>
                  <Button linkto='https://rukabanana.weebly.com/'><CgWebsite />Promotion Site</Button>
                  <Button linkto='https://www.facebook.com/RukaBanana/'><FaFacebookF />Facebook</Button>
                </div>
                <div style={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", marginBottom: "1.66667em", alignItem: "stretch" }}>
                  <div style={{ flex: "1 1 100px" }}>
                    <img className="rukaimg" src="https://i.imgur.com/tbL4uhP.png" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                  </div>
                  <ReactPlayer url="https://www.youtube.com/watch?v=2YwH3InEc4I" style={{ height: "auto", minHeight: "360px", maxWidth: '100%', margin: "0", flex: "1 1 500px" }} />
                </div>
                <Trans t={t} i18nKey="extra1"></Trans>
              </div>
            </Extra>
          </FadeSection>
        </Element>
        {/* right padder */}
        <div className="right-padder">
          <Flash duration={200}>
            {/* menu */}
            <Menu
              items={[
                { name: "intro", target: "intro" },
                { name: "track", target: "track" },
                { name: "thank", target: "thank" },
              ]}
              dimensions={dimensions}
            />
          </Flash>
        </div>
      </div>
      {/* footer */}
      <div className="footer">
        <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "center", padding: "1em", flexWrap: "wrap" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
            <img src={cg_square} width={260} />
            <Button linkto="https://mikura.bandcamp.com/">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ paddingRight: '0.5em' }}><FiShoppingCart /></div>
                <div>
                  <div>$10.0 USD</div>
                  <div style={{ fontSize: "60%" }}>buy on BandCamp</div>
                </div>
              </div>
            </Button>
          </div>
          <div className="footer-content">
            <h2 style={{ margin: "0.5em 0" }}>MiKURA Compilation</h2>
            <p>MKR-001<br />
                  Release. 2021 Feb. 27th, [FF37] R36/R31 - Neo Tokyo Radio<br />
                  <a href="https://www.twitch.tv/mikura_2020" target="_blank">Release Party. 2021 Mar. 20th 18:00-22:00 <GoLinkExternal /></a>
                </p>
            <Trans t={t} i18nKey='t2' />
            <h3 style={{ margin: "0.5em 0" }}>Staff</h3>
            <div className="staff">
              <div>
                <p className="with-job">
                  <span className="job">Producer, Mastering</span><br />
                    3R2
                  <div className="button-list">
                    <Button linkto='https://3r2music.com'><CgWebsite /> 3r2music</Button>
                  </div>
                </p>
                <p className="with-job">
                  <span className="job">Executive Producer</span><br />
                  DiagonalT
                  <div className="button-list">
                    <Button linkto='https://www.facebook.com/djhongtaka'><FaFacebookF /> Facebook</Button>
                    <Button linkto="https://soundcloud.com/hongtaka"><FaSoundcloud /> SoundCloud</Button>
                    <Button linkto="https://www.plurk.com/hongtaka"><PlurkSvg /> Plurk</Button>
                  </div>
                </p>
                <p className="with-job">
                  <span className="job">Coordinator</span><br />
                  Jeff Hazama
                  <div className="button-list">
                    <Button linkto='https://www.facebook.com/JeffHazama'><FaFacebookF />Facebook</Button>
                    <Button linkto="https://www.plurk.com/jack5551555"><PlurkSvg />Plurk</Button>
                    <Button linkto="https://twitter.com/JeffHazama"><FaTwitter />Twitter</Button>
                  </div>
                </p>
                <p className="with-job">
                  <span className="job">Coordinator</span><br />
                FeCo
                <div className="button-list">
                    <Button linkto='https://www.facebook.com/neotokyoradio'><FaFacebookF />Neo Tokyo Radio</Button>
                  </div>
                </p>
                <p className="with-job">
                  <span className="job">CG</span><br />
                Hakurei Neko
                <div className="button-list">
                    <Button linkto='https://twitter.com/Hakurei_Neko'><FaTwitter />Twitter</Button>
                  </div>
                </p>
                <p className="with-job">
                  <span className="job">Design</span><br />
                    srr.
                  </p>
                <p className="with-job">
                  <span className="job">Web Design</span><br />
                catLee
                <div className="button-list">
                    <Button linkto="https://www.plurk.com/leemiyinghao"><PlurkSvg />Plurk</Button>
                    <Button linkto='https://43mmps.catlee.se'><CgWebsite />43mmps</Button>
                  </div>
                </p>
                <p className="with-job">
                  <span className="job">MiKURA Logo Design</span><br />
                    米川
                  </p>
              </div>
              <div>
                <p style={{ gridColumnStart: 1 }}>
                  V!C (ΛΣ)
                <div className="button-list">
                    <Button linkto='https://soundcloud.com/feliaxv'><FaSoundcloud />SoundCloud</Button>
                    <Button linkto="https://www.facebook.com/gaming/VicWithElectroAndYou"><FaFacebookF />Facebook</Button>
                    <Button linkto="https://twitter.com/tatamitako_ViC"><FaTwitter />Twitter</Button>
                  </div>
                </p>
                <p>Spiegel</p>
                <p>Ice
              <div className="button-list">
                    <Button linkto="https://soundcloud.com/iceloki/"><FaSoundcloud />SoundCloud</Button>
                  </div>
                </p>
                <p>
                  Sean Wang
                <div className="button-list">
                    <Button linkto='https://www.youtube.com/user/GODGODGOD10'><FaYoutube />YouTube</Button>
                    <Button linkto="https://soundcloud.com/tmpss91136/tracks"><FaSoundcloud />SoundCloud</Button>
                    <Button linkto="https://www.facebook.com/profile.php?id=100000468720983"><FaFacebookF />Facebook</Button>
                    <Button linkto="https://www.mixcloud.com/sean-wang4/"><FaMixcloud />MixCloud</Button>
                  </div>
                </p>
                <p>
                  XinG
                <div className="button-list">
                    <Button linkto='https://soundcloud.com/xing_lo'><FaSoundcloud />SoundCloud</Button>
                    <Button linkto="https://www.facebook.com/xingl0"><FaFacebookF />Facebook</Button>
                    <Button linkto="https://twitter.com/tom61403"><FaTwitter />Twitter</Button>
                    <Button linkto="https://xinglo.bandcamp.com"><FaBandcamp />BandCamp</Button>
                  </div>
                </p>
                <p>
                  KillerBlood
                <div className="button-list">
                    <Button linkto='https://www.facebook.com/KillerBloodTW'><FaFacebookF />Facebook</Button>
                    <Button linkto="https://killerblood.bandcamp.com"><FaBandcamp />BandCamp</Button>
                    <Button linkto="https://twitter.com/KillerBloodTW"><FaTwitter />Twitter</Button>
                    <Button linkto="https://www.plurk.com/KILLERBLOOD"><PlurkSvg />Plurk</Button>
                  </div>
                </p>
                <p>
                  Ruka Banana
                <div className="button-list">
                    <Button linkto='https://rukabanana.weebly.com/'><CgWebsite />Promotion Site</Button>
                    <Button linkto='https://www.facebook.com/RukaBanana/'><FaFacebookF />Facebook</Button>
                  </div>
                </p>
              </div>
            </div>
            <h3 style={{ margin: "0.5em 0" }}>Special Thanks</h3>
            <div className="staff special">
              <p>米哥窟</p>
              <a href='https://www.twitch.tv/jiaomachannel' target='_blank'>蕉媽頻道 Jiaoma Channel</a>
              <a href='https://facebook.com/futureImages.TW' target='_blank'>未來群像</a>
              <a href='https://www.facebook.com/29otaku/' target='_blank'>29 歐他窟</a>
              <a href='https://www.facebook.com/neotokyoradio' target='_blank'>NeoTokyoRadio 新東京電音台</a>
              <a href='https://www.facebook.com/AnimodeDJ/' target='_blank'>Ani-mode</a>
              <a href='https://www.facebook.com/YanKMW' target='_blank'>Yan-K Media Works</a>
              <a href='https://www.facebook.com/discloax.official/' target='_blank'>D!SCLOAX</a>
              <a href='https://www.facebook.com/travis.minuet' target='_blank'>Tr@vis</a>
              <a href='https://www.facebook.com/RukaBanana/' target='_blank'>Ruka Banana</a>
              <p>火火</p>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '1em' }}>
          <img src={mikura_logo} style={{ height: '30px' }} />
          <p style={{ margin: '0.5em' }}><a href="https://www.facebook.com/Mi-KURA-108964234118667/" target="_blank">MiKURA</a> 2021, All Rights Reserved</p>
        </div>
      </div>
    </React.Fragment >
  );
}

function FadeSection(props) {
  const { children } = props;
  return (<Fade duration={200} distance="1em" down>
    {children}
  </Fade>);
}

export default App;
