import Typography from 'typography'
import sternGroveTheme from 'typography-theme-stern-grove'

sternGroveTheme.bodyColor = "rgb(219,219,219)";
sternGroveTheme.bodyFontFamily = ["Fira Sans", "Noto Sans TC", "M PLUS 1p"];
sternGroveTheme.headerColor = "rgb(219,219,219)";
sternGroveTheme.headerFontFamily = ["Montserrat", "Noto Sans TC", "M PLUS 1p"];
sternGroveTheme.headerWeight = 200;
sternGroveTheme.baseFontSize = 15;
sternGroveTheme.googleFonts = [
    {
        name: "Fira Sans",
        styles: [400]
    },
    {
        name: "Montserrat",
        styles: [200, 400]
    },
    {
        name: "Noto Sans TC",
        styles: [100, 400]
    },
    {
        name: "M PLUS 1p",
        styles: [100, 400]
    },
]
const typography = new Typography(sternGroveTheme);

export default typography;