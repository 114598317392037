
import React from 'react';
import { Element } from 'react-scroll';
import ImagePlaceholder from "../assets/placeholder-images-image_large.jpg";
import { GoLinkExternal } from 'react-icons/go';
import cg_square from '../assets/cg_square.jpg';
import Fade from 'react-reveal/Fade';
import Button from './button';
import { FiShoppingCart, FiLock } from 'react-icons/fi';
import { Trans, useTranslation } from 'react-i18next';

export function Splash() {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      <div className="splash-outer">
        <div style={{ flexBasis: "50%", flexShrink: 0, display: "flex", alignItems: "center", padding: "1em", marginTop: "100vh", minHeight: "100vh" }}>
          <Fade opposite>
            {/* detail */}
            <Element name="detail">
              <p>MKR-001</p>
              <p><b>release.</b><br />2021 Feb. 27th<br />FF37: Neo Tokyo Radio - R36/R31</p>
              <p><b>release party.</b><br />2021 Mar. 20th 18:00-22:00<br /><span className="xx-smaller">DJ Jeff Hazama // DiagonalT // 3R2 // Ice // Sean Wang // V!C // XinG // KillerBlood</span><br />
                <a href="https://www.twitch.tv/mikura_2020" target="_blank">Live on Twitch <GoLinkExternal /></a></p>
              <Trans t={t} i18nKey='t2' />
              <Button linkto="https://mikura.bandcamp.com/">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ paddingRight: '1em', flexGrow: "0", flexBasis: 'content' }}><FiShoppingCart /></div>
                  {/* <div style={{ paddingRight: '1em', flexGrow: "0", flexBasis: 'content' }}><FiLock /></div> */}
                  <div style={{ flexGrow: '1' }}>
                    <div>$10.0 USD</div>
                    <div style={{ fontSize: "60%" }}>buy on BandCamp</div>
                    {/* <div style={{ fontSize: "60%" }}>online purchase coming soon</div> */}
                  </div>
                </div>
              </Button>
            </Element>
          </Fade>
        </div>
        <div style={{ flexBasis: "50%", flexShrink: 0, display: "flex", alignItems: "center", padding: "1em", position: "sticky", top: 0, height: "100vh", maxWidth: "100vw" }}>
          <Fade opposite>
            <img src={cg_square} style={{ width: "100%", margin: 0 }} />
          </Fade>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Splash;
