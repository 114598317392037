import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
   .use(Backend)
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
      react: {
         useSuspense: false
      },
      backend: {
         loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
      fallbackLng: "en",
      supportedLngs: ['en', 'zh-TW'],
      interpolation: {
         escapeValue: false,
      },
      debug: true,
      detection: {
         order: ['querystring', 'navigator'],
         lookupQuerystring: 'lng'
      }
   });

i18n.changeLanguage();

export default i18n;