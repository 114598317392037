import React from "react";

export function ExpendingContent(props) {
    const [collapse, setCollapse] = React.useState(true);
    return (<React.Fragment>
        <div className={collapse ? "expending-content collapsed" : "expending-content"}>
            {props.children}
        </div>
        {collapse && <p className="expending-button" onClick={() => setCollapse(false)}>more...</p>}
    </React.Fragment>);
}
export default ExpendingContent;